
.home-container {
    font-family: 'Roboto', sans-serif;
    padding: 2rem;
    background-color: #f0f8ff;
    /* Light blue background */
    color: #333;
    /* Dark text color */
    max-width: 1200px;
    margin: 0 auto;
}

.home-container .animated-section {
    /* max-width: 900px; */
    /* margin: 0 auto 40px auto; */
    /* text-align: left; */
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 2s, transform 2.5s;
    /* padding: 2rem; */
}

.home-container .animated-section.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Animation */
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Improved Introduction Section */
.intro-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    /* margin-top: 2rem; */
    padding: 2rem;
    /* background-color: #ffffff; */
    border-radius: 5px;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
}

.intro-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 2rem;
}

.intro-text h1 {
    font-size: 4rem;
    font-family: 'Courier Prime', monospace;
    /* Typewriter font */
    animation: typing 3s steps(40, end), blink 1s step-end infinite;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid #007bff;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

.intro-text p {
    font-size: 1.5rem;
    margin-top: 1rem;
}

.explore-button {
    padding: 0.75rem 2rem;
    font-size: 1rem;
    border: none;
    background-color: #007bff;
    /* Vibrant blue button */
    color: white;
    cursor: pointer;
    border-radius: 2rem;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
    text-decoration: none;
}

.explore-button:hover {
    background-color: #116dcf;
    /* Darker shade of blue on hover */
}

.intro-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
}

.intro-image img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.intro-image img:hover {
    transform: scale(1.05);
}

/* Improved Projects Section */
.projects-section {
    margin-top: 4rem;
}

.projects-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.project-card {
    background-color: white;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: translateY(-5px);
}

.home-project-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    /* margin-bottom: 1rem; */
}

.project-info {
    flex: 1;
    padding: 0 1rem 1.5rem 1rem;
}

.project-info:hover {
    background: lightblue;
}

.project-info h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.project-info p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    /* line-height: 1.8; */
    color: #555;
    display: -webkit-box;            /* Enables the box model needed for line clamping */
    -webkit-box-orient: vertical;    /* Sets the box's orientation to vertical */
    -webkit-line-clamp: 3;           /* Limits the text to 2 lines */
    overflow: hidden;                /* Hides any overflowing text */
    text-overflow: ellipsis;         /* Adds ellipsis (…) to indicate clipped text */
    /* line-height: 1.5;              //  Adjust the line height as needed 
    max-height: 3em;                 //Sets a max height based on the line height and line clamp */
}

.view-project-button {
    position: relative;
    font-size: 1.2rem;
    border: none;
    color: #007bff;
    cursor: pointer;
    border-radius: 3px;
    transition: color 0.3s ease;
    margin-top: 1rem;
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
}

.view-project-button::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -3px;
    width: 0;
    height: 2px;
    background-color: #007bff;
    transition: width 0.3s ease, left 0.3s ease;
}

.view-project-button:hover::after {
    width: 100%;
    left: 0;
}

.view-project-button:hover {
    color: #116dcf;
    /* Change text color on hover */
}


.contact-arrow-icon {
    color: hsl(211, 100%, 50%);
    font-size: 2.5rem;
}

.contact-arrow-icon:hover {
    color: #116dcf;
    font-size: 2.7rem;
}

.arrow-icon {
    margin-left: 0.5rem;
}

/* Improved Services Section */
.services-section {
    margin-top: 4rem;
}

.services-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.service-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2.5rem;
}

.service-item {
    background-color: lightblue;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.service-item:hover {
    transform: translateY(-5px);
}

.service-item h3 {
    color: #007bff;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.service-item p {
    font-size: 1rem;
}

/* Improved Contact Section */
.contact-section {
    margin-top: 4rem;
    background-color: lavender;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.3s ease;
    position: relative;
    overflow: hidden;
}

.contact-section:hover {
    background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), lightblue, transparent 80%);

}


/* Optional: To make sure the element's background smoothly transitions back */
.contact-section:not(:hover) {
    background: lavender;
}

.contact-section h2 {
    font-size: 2.5rem;
    color: #007bff;
    margin-right: 2rem;
}

.contact-info {
    flex: 1;
    font-size: 1.2rem;
}

.contact-info a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-info a:hover {
    color: #116dcf;
}

.contact-icons {
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
}

.contact-icons a {
    color: #007bff;
    transition: color 0.3s ease;
}

.contact-icons a:hover {
    color: #116dcf;
}

@media (max-width: 992px) {
    .intro-text h1 {
        /* font-size: 3.5rem; */
    }
}


@media (max-width: 768px) {

    .home-container {
        padding: .5rem;
    }

    .service-item {
        padding: 1rem;
    }

    .intro-section {
        text-align: center;
        flex-direction: column;
        /* On small screens, intro text below image */
        margin-top: 0;
        padding: 0;
    }

    .intro-text {
        margin: 0;
        align-items: center;
    }

    .intro-text h1 {
        font-size: 3rem;
    }

    .intro-image {
        margin: 0;
    }

    .contact-section {
        flex-direction: column;
        padding: 1rem;
        text-align: start;
    }

    .contact-section h2 {
        margin-bottom: 1rem;
    }

    .contact-info {
        margin-bottom: 1rem;
    }
}


@media (max-width: 650px) {
    .intro-text h1 {
        font-size: 2.5rem;
    }
}

@media (max-width: 576px) {
    .intro-text h1 {
        font-size: 2rem;
    }
}

@media (max-width: 387px) {
    .intro-text h1 {
        font-size: 1.5rem;
    }
}

@media (max-width: 300px) {
    .intro-text h1 {
        font-size: 1.2rem;
    }
}

@media (max-width: 250px) {
    .intro-text h1 {
        font-size: 1rem;
    }
}