@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Courier+Prime:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.mainContainer {
  background-color: #f0f8ff;
  /* background-color: #fff; */
}

/* .zoom-80 {
  transform: scale(0.8);
    transform-origin: top left; 
    width: 125%; 
    height: 125%; 
} */


.center-placeholder {
  position: fixed; /* Ensures the div covers the entire screen */
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  display: flex;
  justify-content: center; /* Horizontally center the image */
  align-items: center; /* Vertically center the image */
  background-color: #f0f8ff; /* White background */
  z-index: 999; /* Ensures it is on top of other content */
  flex-direction: column;
}



.center-placeholder img {
  opacity: 0.3; /* Adjust this for transparency */
  max-width: 50%; /* Make sure the logo fits well */
  max-height: 50%; /* Adjust size to fit well */
}


.slow-loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: rgb(51, 0, 0);
}

.center-loading-animation {
  opacity: 0.3; /* Adjust this for transparency */
  margin-top: 20px;
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #4A6FB1, #00BFFF);
  animation: centerLoadingBar 2s infinite;
}

@keyframes centerLoadingBar {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(150%);
  }
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* required many places */

.matrix-effect {
  /* font-size: 3.5rem; */
  font-family: 'Courier Prime', monospace;
  /* color: #007bff; Vibrant blue */
  margin-bottom: 0.5rem;
  /* white-space: nowrap; */
  overflow: hidden;
  /* animation: typing 2s steps(30, end), blink-caret 0.75s  infinite; */
  animation: fadeIn .5s ease-in, 4s steps(40, end) forwards, blink-caret 0.75s step-end infinite;

}


.about-hero {
  text-align: center;
  padding: 2rem 0;
  margin-bottom: 2rem;
  /* animation: fadeIn 1s ease-in; */
}

.about-hero h1 {
  font-size: 4rem;
  font-family: 'Roboto', sans-serif;
  /* color: #007bff; */
  /* Vibrant blue */
  margin-bottom: 2rem;
  animation: slideInDown 1s ease-out;
}


.about-hero p {
  font-size: 1.5rem;
  color: #555;
  /* Medium gray */
  /* animation: slideInUp 1s ease-out; */
}

.view-all-projects {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  color: hsl(211, 100%, 50%);
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: none;
}

.view-all-projects:hover {
  color: #116dcf;
}

.project-card {
  background-color: white;
  border-radius: 5px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
}

.action-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  /* Vibrant blue button */
  color: #007bff;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 1rem;
  margin-top: 1rem;
  border: 1px solid #007bff;
  /* Border color and thickness */
  transition: background-color 0.5s ease, color 0.3s ease;
  background-color: lavender;
  overflow: hidden;
  /* transition: transform 0.3s ease, opacity 0.3s ease; */
  /* Smooth transition for background and text color */
}

.action-button:hover {
  background-color: #007bff;
  border: 1px solid #007bff;

  /* Darker shade of blue on hover */
  color: white;
}




/* Modal styles */
.modal {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: lavender;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 100%;
  text-align: center;
  position: relative;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}

.contact-form input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.submit-button {
  padding: 0.25rem .5rem;
  font-size: 1rem;
  background-color: lavender;
  /* Vibrant blue button */
  color: #007bff;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #007bff;
  /* Border color and thickness */
  transition: background-color 0.5s ease, color 0.3s ease;
  /* Smooth transition for background and text color */
}

.submit-button:hover {
  background-color: #007bff;
  border: 1px solid #007bff;

  /* Darker shade of blue on hover */
  color: white;
}

.error {
  color: red;
  margin-top: 5px;
}


@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

@keyframes typing {
  from {
      width: 0
  }

  to {
      width: 100%
  }
}

@keyframes blink-caret {

  from,
  to {
      border-color: transparent
  }

  50% {
      border-color: orange;
  }
}


::-webkit-scrollbar {display:none;} 

