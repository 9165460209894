
.footer {
    background-color: #f0f8ff; /* Light blue background */
    color: #333; /* Dark text color */
    padding: 1rem 1rem;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    border-top: 1px solid #ccc; /* Light border on top */
}

.footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

.footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    flex: 1;
}

.footer-logo img {
    width: 120px;
    margin-bottom: .5rem;
}

.footer-links {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.footer-links a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #007bff;
}

.footer-social {
    gap: 1rem;
}

.footer-social a {
    color: #333;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.footer-social a:hover {
    color: #007bff;
}

.footer-map {
    margin: 2rem 0;
}

.footer-bottom {
    border-top: 1px solid #ccc;
    padding-top: .5rem;
    font-size: 0.9rem;
}

.footer-bottom p {
    margin: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer {
        padding: 1rem .5rem;
    }
    
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-links {
        justify-content: center;
    }

    .footer-row {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .footer-map iframe {
        width: 100%;
        height: 300px;
    }
}
