/* Topbars.css */

.container {
    font-family: 'Roboto', sans-serif;
}

.topnav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: lavender;
    /* padding: .1rem 0; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky; /* Make the navbar sticky */
    top: 0; /* Stick to the top of the viewport */
    z-index: 1000; /* Ensure it appears above other content */
    transition: all 1.2s ease;
}

.logo {
    display: block;
    transition: transform 1.2s ease, opacity 1.2s ease;
    margin-left: 1rem;
}

.hide-logo {
    transform: translateY(100%);
    opacity: 0;
}

.show-logo {
    transform: translateY(0);
    opacity: 1;
}

.logo-img {
    height: 4rem;
    width: 4rem;
    transition: transform 0.5s ease-in-out;
}

.logo-img:hover {
    transform: rotate(360deg);
}

.bars {
    display: none;
    cursor: pointer;
    font-size: 2rem;
    color: #007bff;
    margin-right: 1rem;
    font-weight: 900;
    white-space: nowrap;
    user-select: none; /* Prevent text selection on double-click */
}

.closeBars {
    display: none;
    cursor: pointer;
    font-size: 2rem;
    color: #000;
    margin-right: 1rem;
    font-weight: 900;
    white-space: nowrap;
    user-select: none; /* Prevent text selection on double-click */
}

.hamburger-menu {
    font-size: 2rem;
}

.bars:active .hamburger-menu,
.bars:focus .hamburger-menu,
.bars:hover .hamburger-menu {
    color: inherit; /* Maintain original color */
    background-color: transparent; /* No background color change */
    outline: none; /* Remove outline */
    border: none; /* Remove border */
}
/* 
.menu {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    transition: transform 1.2s ease, opacity 1.2s ease;

    transition: all 0.7s ease; 


} */

.menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    transition: all 0.5s ease;
    position: relative;
    margin: 1rem;    
}


.menu.centered {
    justify-content: center;
    flex-grow: 1;
}

.link {
    color: black;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s, transform 0.3s;
}

.link:hover {
    color: #116dcf;
    transform: scale(1.1);
}

.active {
    color: #007bff;
    transform: scale(1.1);
    font-weight: 600;
}

@media (max-width: 768px) {
    .bars {
        display: block;
    }
    .closeBars {
        display: block;
    }

    .topnav {
        padding: 0;
        align-items: end;
    }

    .logo-img {
        height: 3rem;
        width: 3rem;
    }

    .menu {
        display: none;
        flex-direction: column;
        gap: 1rem;
        position: absolute;
        top: 3rem;
        left: 0;
        background-color: lavender;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 1rem 0;
        width: 100%;
        margin: 0;

    }

    .menu.open {
        display: flex;
        width: 100%;
    }

    .menu.centered {
        position: relative; /* Adjust for centered menu */
        top: 0;
        left: 0;
        width: auto;
    }

    .link {
        color: black;
        text-decoration: none;
        font-size: 1.5rem;
        transition: color 0.3s, transform 0.3s;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    
    .link:hover {
        color: #116dcf;
        transform: scale(1.1);
    }
    
    .active {
        color: #007bff;
        transform: scale(1.1);
        font-weight: 600;
    }
}

@media (min-width: 769px) {
    .menu {
        display: flex;
    }

    .menu.open {
        display: flex;
    }
}

@media (min-width: 1024px) {
    .menu {
        display: flex;
        margin-right: 1rem;
    }

    .menu.open {
        display: flex;
    }
}


